import React from 'react'
import { graphql } from 'gatsby'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import SliderSearch from 'components/SliderSearch'

export default function DiamondsSearchPage({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiDiamondsSearchPage
  // const parent = data.strapiDiamondsPage
  const { title } = page
  const customBanner = {
    // pretitle: parent.title,
    // pretitle: tr('diamonds', locale),
    pretitle: ' ',
    title,
  }

  return (
    <div className='page-diamonds-search'>
      <BannerTitleOnlyBlock
        content={customBanner}
        padTop={true}
        padBottom={true}
        lineBottom={true}
      />
      <SliderSearch data={data} />
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiDiamondsPage(locale: { eq: $locale }) {
      title
    }
    strapiDiamondsSearchPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
    }
    allStrapiDiamondReserve(filter: { locale: { eq: $locale } }) {
      maxPrice: max(field: price)
      maxCarat: max(field: carat)
      nodes {
        shape
        cut
        color
        clarity
        carat
        price
        certId
        certType
      }
    }
  }
`
