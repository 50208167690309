import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

const Image = ({ filename, className = '' }) => {
  const [ref, inView] = useInView()
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            nodes {
              gatsbyImageData(width: 68)
              parent {
                ... on File {
                  base
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const getNode = data.allImageSharp.nodes.find(
          (node) => node.parent?.base === filename
        )
        const image = getImage(getNode)
        if (!image) {
          return null
        }
        return (
          <div
            className={`
            ${inView ? ' in-view' : ''} 
            fade-in ${className}
          `}
            ref={ref}
          >
            <GatsbyImage image={image} placeholder='none' alt='' />
          </div>
        )
      }}
    />
  )
}
export default Image
