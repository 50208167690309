import React, { useState } from 'react'
import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css'
import ImageWrapLocal from 'components/ImageWrapLocalDiamondShape'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import wNumb from 'wnumb'

export default function SliderSearch({ data }) {
  const { locale } = useLocalization()

  let diamondReserves = data.allStrapiDiamondReserve.nodes
  let { maxPrice, maxCarat } = data.allStrapiDiamondReserve
  const exchangeRate = locale === 'en' ? 6.5 : 1

  diamondReserves.forEach(
    (item) => (item.priceLocal = Math.round(item.price / exchangeRate))
  )
  const maxPriceLocal = Math.round(maxPrice / exchangeRate)

  const shapes = [
    'round',
    'oval',
    'heart',
    'pear',
    'marquise',
    'emerald',
    'cushion',
    'princess',
    'asscher',
    'radiant',
  ]
  const filters = {
    priceLocal: {
      name: 'priceLocal',
      title: tr('diamondPrice', locale),
      min: 0,
      max: maxPriceLocal,
      decimals: 0,
    },
    carat: {
      name: 'carat',
      title: tr('diamondCarat', locale),
      min: 0,
      max: maxCarat,
      decimals: 2,
    },
    color: {
      name: 'color',
      title: tr('diamondColor', locale),
      // range: ['H', 'G', 'F', 'E', 'D'],
      range: ['D', 'E', 'F', 'G', 'H'],
    },
    cut: {
      name: 'cut',
      title: tr('diamondCut', locale),
      // range: ['very_good', 'excellent', 'ideal'],
      range: ['ideal', 'excellent', 'very_good'],
    },
    clarity: {
      name: 'clarity',
      title: tr('diamondClarity', locale),
      // range: ['VS2', 'VS1', 'VVS2', 'VVS1', 'IF', 'FL'],
      range: ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2'],
    },
  }

  const [activeFilterState, setActiveFilterState] = useState(filters)
  const [activeShapesState, setActiveShapesState] = useState([])

  const updateActiveFilterState = (name, range) => {
    setActiveFilterState((prevState) => ({
      ...prevState,
      [name]: {
        name,
        min: range[0],
        max: range[1],
      },
    }))
  }
  const updateActiveShapesState = (name) => {
    let newState = [...activeShapesState]
    if (newState.includes(name)) {
      newState.splice(newState.indexOf(name), 1)
    } else {
      newState.push(name)
    }
    setActiveShapesState(newState)
  }

  let sliderSet = []
  for (var key in filters) {
    if (filters[key].range) {
      sliderSet.push(
        <SliderSteps
          key={key}
          content={filters[key]}
          updateActiveFilterState={updateActiveFilterState}
        />
      )
    } else {
      sliderSet.push(
        <Slider
          key={key}
          content={filters[key]}
          updateActiveFilterState={updateActiveFilterState}
        />
      )
    }
  }

  return (
    <div className='component-slider-search container'>
      <Inview className='input pad-v-standard fade-in up'>
        <ShapesSelect
          shapes={shapes}
          content={filters.shapes}
          activeShapesState={activeShapesState}
          updateActiveShapesState={updateActiveShapesState}
        />
        {sliderSet}
      </Inview>

      <Results
        content={diamondReserves}
        filters={filters}
        activeShapesState={activeShapesState}
        activeFilterState={activeFilterState}
      />
    </div>
  )
}

const ShapesSelect = ({
  shapes,
  activeShapesState,
  updateActiveShapesState,
}) => {
  const { locale } = useLocalization()
  return (
    <div className='field shapes-select'>
      <h4 className='title'>{tr('diamondShape', locale)}</h4>
      <div className='inner'>
        {shapes.map((name, i) => (
          <ShapesSelectItem
            key={i}
            name={name}
            activeShapesState={activeShapesState}
            updateActiveShapesState={updateActiveShapesState}
          />
        ))}
      </div>
    </div>
  )
}

const ShapesSelectItem = ({
  name,
  activeShapesState,
  updateActiveShapesState,
}) => {
  const { locale } = useLocalization()
  const activeClass = activeShapesState.includes(name) ? 'active' : ''
  let label = tr(`diamondShape_${name}`, locale)
  return (
    <div
      className={`item ${activeClass}`}
      onClick={() => updateActiveShapesState(name)}
    >
      <div className='image'>
        <div className='inverse'>
          <ImageWrapLocal filename={`${name}_invert.png`} />
        </div>
        <div className='standard'>
          <ImageWrapLocal filename={`${name}.png`} />
        </div>
      </div>
      <p className='title'>{label}</p>
    </div>
  )
}

const Slider = ({ content, updateActiveFilterState }) => {
  const { locale } = useLocalization()
  const { name, title, min, max, decimals } = content
  const [sliderRef, setSliderRef] = useState(null)

  const onUpdate = (render, handle, value, un, percent) => {
    document.querySelector(`.${name}.min`).value = render[0]
    document.querySelector(`.${name}.max`).value = render[1]
    let valuesToDecimals = [
      value[0].toFixed(decimals),
      value[1].toFixed(decimals),
    ]
    updateActiveFilterState(name, valuesToDecimals)
  }
  const onInputChange = (e) => {
    if (e.type === 'keypress' && e.key !== 'Enter') return
    const newValue = e.target.value
    const minMax = e.target.classList[1]
    if (minMax === 'min') {
      sliderRef.noUiSlider.set([newValue, null])
    }
    if (minMax === 'max') {
      sliderRef.noUiSlider.set([null, newValue])
    }
  }

  let prefix = ''
  if (name === 'priceLocal') {
    prefix = tr('currencySign', locale) + ' '
  }
  return (
    <div className='field slider'>
      <h4 className='title'>{title}</h4>
      <div className='inner'>
        <div className='input-wrap'>
          <input
            className={`${name} min`}
            type='text'
            onBlur={onInputChange}
            onKeyPress={onInputChange}
          />
          <input
            className={`${name} max`}
            type='text'
            onBlur={onInputChange}
            onKeyPress={onInputChange}
          />
        </div>
        <Nouislider
          instanceRef={(instance) => {
            setSliderRef(instance)
          }}
          connect
          start={[min, max]}
          behaviour='tap'
          range={{
            min: [min],
            // '10%': [500, 500],
            // '50%': [4000, 1000],
            max: [max],
          }}
          format={wNumb({
            decimals,
            thousand: ',',
            prefix,
          })}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  )
}

const SliderSteps = ({ content, updateActiveFilterState }) => {
  const { locale } = useLocalization()

  const { name, title, range } = content

  const onUpdate = (render, handle, value, un, percent) => {
    updateActiveFilterState(name, value)
  }

  return (
    <div className='field slider slider-steps'>
      <h4 className='title'>{title}</h4>
      <div className='inner'>
        <div className='range-label'>
          {range.map((label, i) => {
            if (name === 'cut') {
              label = tr(`diamondCut_${label}`, 'en')
            }
            return (
              <div
                key={i}
                className='item'
                style={{ width: `calc(1 / ${range.length} * 100%)` }}
              >
                {label}
              </div>
            )
          })}
        </div>
        <Nouislider
          connect
          start={[0, range.length]}
          behaviour='tap'
          step={1}
          range={{
            min: [0],
            max: [range.length],
          }}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  )
}

const Results = ({
  content,
  filters,
  activeShapesState,
  activeFilterState,
}) => {
  const { locale } = useLocalization()
  let activeContent = content

  if (activeShapesState.length > 0) {
    activeContent = activeContent.filter((row) => {
      return activeShapesState.includes(row.shape)
    })
  }

  for (const key in filters) {
    if (filters[key].range) {
      activeContent = activeContent.filter((row) => {
        row.index = filters[key].range.indexOf(row[key])
        return (
          row.index >= activeFilterState[key].min &&
          row.index < activeFilterState[key].max
        )
      })
    } else {
      activeContent = activeContent.filter((row) => {
        return (
          row[key] >= activeFilterState[key].min &&
          row[key] <= activeFilterState[key].max
        )
      })
    }
  }

  let filterTitles = []
  for (const key in filters) {
    filterTitles.push(filters[key].name)
  }

  let notice = {
    en: '*Affected by real-time exchange rate fluctuations: please refer to the current price when purchasing. ',
    zh: '*受实时汇率波动影响，购买时请以当前价格为准。',
  }
  notice = notice[locale]

  return (
    <div className='results pad-v-standard'>
      <Inview className='title fade-in up'>
        <h3>
          {tr('resultsFavorite', locale)} ({activeContent.length})
        </h3>
      </Inview>
      <div className='table'>
        <Inview className='th fade-in up'>
          <div className='td'>
            <h4>{tr('diamondCert', locale)}</h4>
          </div>
          <div className='td'>
            <h4>{tr('diamondShape', locale)}</h4>
          </div>
          <div className='td center'>
            <h4>{tr('diamondCarat', locale)}</h4>
          </div>
          <div className='td center'>
            <h4>{tr('diamondCut', locale)}</h4>
          </div>
          <div className='td center'>
            <h4>{tr('diamondColor', locale)}</h4>
          </div>
          <div className='td center'>
            <h4>{tr('diamondClarity', locale)}</h4>
          </div>
          <div className='td right'>
            <h4>*{tr('diamondPrice', locale)}</h4>
          </div>
        </Inview>
        {activeContent &&
          activeContent.map((item, i) => <TableRow key={i} content={item} />)}
      </div>
      <Inview className='fade-in up'>
        <p className='notice'>{notice}</p>
      </Inview>
    </div>
  )
}

const TableRow = ({ content }) => {
  const { locale } = useLocalization()
  const { certId, certType, shape, carat, cut, color, clarity, priceLocal } =
    content

  let idHtml = certId
  let idLink = ''
  if (certType) {
    if (certType === 'IGI') {
      idLink = `https://www.igi.org.cn/reports/verify-your-report?r=${certId}`
    }
    if (certType === 'GIA') {
      idLink = `https://www.gia.edu/CN/report-check?reportno=${certId}`
    }
    idHtml = (
      <a href={idLink} target='_blank'>
        {certId}
      </a>
    )
  }

  return (
    <Inview className='tr fade-in up'>
      <div className='td'>{idHtml}</div>
      <div className='td shape'>
        <div className='image'>
          <ImageWrapLocal filename={`${shape}.png`} />
        </div>
        {tr(`diamondShape_${shape}`, locale)}
      </div>
      <div className='td center'>{carat}</div>
      <div className='td center'>{tr(`diamondCut_${cut}`, 'en')}</div>
      <div className='td center'>{color}</div>
      <div className='td center'>{clarity}</div>
      <div className='td right'>
        {tr('currencySign', locale)} {priceLocal.toLocaleString()}
      </div>
    </Inview>
  )
}

const SliderPercent = () => {
  const [textValue, setTextValue] = useState(null)
  const [percent, setPercent] = useState(null)

  const onSlide = (render, handle, value, un, percent) => {
    // setTextValue(value[0].toFixed(2))
    // setPercent(percent[0].toFixed(2))
  }
  return (
    <div className='pad-v-standard'>
      <Nouislider
        connect
        // start={[500, 4000]}
        behaviour='tap'
        range={{
          min: [0],
          // '10%': [500, 500],
          // '50%': [4000, 1000],
          max: [10000],
        }}
        onSlide={onSlide}
      />
      <div>
        Value: {textValue}, {percent} %
      </div>
    </div>
  )
}
